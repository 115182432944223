import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/post-card"
import Tags from "../components/tags"


export default function NewsPage ({
  data: {
    allMarkdownRemark: { edges }
  }
}) {
  const tags = new Set();
  const Posts = edges.map(edge => {
      const post = edge.node;
      tags.add(post.frontmatter.tag);

      return (
        <div key = {post.id}>
          <PostCard title = {post.frontmatter.title} date = {post.frontmatter.date} excerpt = {post.excerpt} slug = {post.frontmatter.slug} tag = {post.frontmatter.tag} path = {post.frontmatter.path}/>
          <br/>
        </div>
          
      )
  });

  return (
    <Layout>
      <SEO title = "News"/>
      <section>
      <div id = "posts" name = "posts" className = "font-montserrat text-4xl text-red-dark">News</div>
      <br/>
      <Tags tags={Array.from(tags)}/>
      <br/>
      {Posts}
      </section>
    </Layout>
  ) 
}

export const query = graphql`
{
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        excerpt(pruneLength: 250)
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          slug
          tag
          title
          path
        }
      }
    }
  }
}
`;

