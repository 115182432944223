import React from "react"

const dict = {
    "FORESTprojectEU": "/news/forest",
    "valUEprojectEU": "/news/value",
    "CyberSeniorsprojectEU": "news/cyberseniors"
}

export default function Tags({tags}) {

    return(
        <div>
            {tags.map((tag) => (
                <a key = {tag} href={dict[tag]} className="inline-block font-montserrat text-md bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300">#{tag}</a>
            ))}
        </div>
    )
}